<template>
    <div class="form-container" v-if="value">
        <!-- 작품 정보 -->
        <div>
            <div class="mb-10">
                <h3 class="tit tit--xs">문의 작품</h3>
            </div>
            <div class="table-style table-style--default">
                <v-row align="center">
                    <v-col cols="auto">
                        <bids-item-imgs :imgs="form?.work?.imgs || []" />
                    </v-col>
                    <v-col>
                        <div class="py-10 py-md-14">
                            <div>
                                <strong class="font-size-14 font-weight-medium">LOT. {{ form?.work?.lot }}</strong>
                            </div>
                            <div class="mt-8 mt-md-10 ellip">
                                <strong class="font-size-20 font-size-md-24 font-weight-medium font-notoserif">{{ form?.work?.author?.name?.ko }}</strong>
                            </div>
                            <div class="mt-md-2 ellip">
                                <span class="font-size-16 font-size-md-18 font-notoserif">{{ form?.work?.subject }}</span>
                            </div>
                            <div class="mt-10 mt-md-12">
                                <span class="font-size-14 grey-68--text">{{ form?.work?.year }} ㅣ {{ form?.work?.material }} ㅣ {{ form?.work?.dimensionTag }}</span>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <!-- 작품 정보 -->

        <!-- 문의자 정보 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">문의자 정보</h3>
            </div>
            <v-divider class="primary border-2"></v-divider>
            <div class="py-20 px-md-10 py-md-36">
                <div class="mt-n8 mt-md-n14">
                    <myinfo-row label="이름"> {{ form?.meta?.name || "-" }} </myinfo-row>

                    <myinfo-row label="연락처"> {{ form?.meta?.phone || "-" }} </myinfo-row>
                    <myinfo-row label="이메일"> {{ form?.meta?.email || "-" }} </myinfo-row>
                </div>
            </div>
            <v-divider />
        </div>
        <!-- 문의자 정보 -->

        <!-- 문의 내용 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">문의내용</h3>
            </div>
            <v-divider class="primary border-2"></v-divider>
            <div class="py-20 px-md-10 py-md-36">
                <div class="mt-n8 mt-md-n14">
                    <myinfo-row label="제목"> {{ form?.subject || "-" }} </myinfo-row>
                    <myinfo-row label="내용"> {{ form?.content || "-" }} </myinfo-row>
                </div>
            </div>
            <v-divider />
        </div>
        <!-- 문의 내용 -->

        <!-- 답변 내용 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">답변 내용</h3>
            </div>
            <v-card tile elevation="0" class="grey-ef">
                <v-card-text class="pa-16 pa-md-24">
                    <div class="page-text" v-html="form?.reply || '답변 내용이 없습니다.'"></div>
                </v-card-text>
            </v-card>
        </div>
        <!-- 답변 내용 -->

        <!-- 하단버튼 -->
        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">목록으로</span></v-btn>
                <!-- <v-btn x-large color="primary" class="w-100 mw-220px" @click="$emit('save')">위탁신청</v-btn> -->
            </div>
        </div>
    </div>
</template>

<script>
import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
// import BidsItemImgs from "@/components/client/mypage/bids-item-imgs.vue";

export default {
    components: {
        MyinfoRow,
        ImagePopup,
        // BidsItemImgs,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            form: this.$props.value,
        };
    },
    watch: {
        value() {
            this.form = this.value;
        },
    },
    methods: {
        input() {
            this.$emit("input", this.form);
        },
    },
};
</script>
