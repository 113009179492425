var render = function render(){
  var _vm$form, _vm$form$work, _vm$form2, _vm$form2$work, _vm$form3, _vm$form3$work, _vm$form3$work$author, _vm$form3$work$author2, _vm$form4, _vm$form4$work, _vm$form5, _vm$form5$work, _vm$form6, _vm$form6$work, _vm$form7, _vm$form7$work, _vm$form8, _vm$form8$meta, _vm$form9, _vm$form9$meta, _vm$form10, _vm$form10$meta, _vm$form11, _vm$form12, _vm$form13;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('div', {
    staticClass: "form-container"
  }, [_c('div', [_vm._m(0), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('bids-item-imgs', {
    attrs: {
      "imgs": ((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$work = _vm$form.work) === null || _vm$form$work === void 0 ? void 0 : _vm$form$work.imgs) || []
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "py-10 py-md-14"
  }, [_c('div', [_c('strong', {
    staticClass: "font-size-14 font-weight-medium"
  }, [_vm._v("LOT. " + _vm._s((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$work = _vm$form2.work) === null || _vm$form2$work === void 0 ? void 0 : _vm$form2$work.lot))])]), _c('div', {
    staticClass: "mt-8 mt-md-10 ellip"
  }, [_c('strong', {
    staticClass: "font-size-20 font-size-md-24 font-weight-medium font-notoserif"
  }, [_vm._v(_vm._s((_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : (_vm$form3$work = _vm$form3.work) === null || _vm$form3$work === void 0 ? void 0 : (_vm$form3$work$author = _vm$form3$work.author) === null || _vm$form3$work$author === void 0 ? void 0 : (_vm$form3$work$author2 = _vm$form3$work$author.name) === null || _vm$form3$work$author2 === void 0 ? void 0 : _vm$form3$work$author2.ko))])]), _c('div', {
    staticClass: "mt-md-2 ellip"
  }, [_c('span', {
    staticClass: "font-size-16 font-size-md-18 font-notoserif"
  }, [_vm._v(_vm._s((_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : (_vm$form4$work = _vm$form4.work) === null || _vm$form4$work === void 0 ? void 0 : _vm$form4$work.subject))])]), _c('div', {
    staticClass: "mt-10 mt-md-12"
  }, [_c('span', {
    staticClass: "font-size-14 grey-68--text"
  }, [_vm._v(_vm._s((_vm$form5 = _vm.form) === null || _vm$form5 === void 0 ? void 0 : (_vm$form5$work = _vm$form5.work) === null || _vm$form5$work === void 0 ? void 0 : _vm$form5$work.year) + " ㅣ " + _vm._s((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : (_vm$form6$work = _vm$form6.work) === null || _vm$form6$work === void 0 ? void 0 : _vm$form6$work.material) + " ㅣ " + _vm._s((_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : (_vm$form7$work = _vm$form7.work) === null || _vm$form7$work === void 0 ? void 0 : _vm$form7$work.dimensionTag))])])])])], 1)], 1)]), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(1), _c('v-divider', {
    staticClass: "primary border-2"
  }), _c('div', {
    staticClass: "py-20 px-md-10 py-md-36"
  }, [_c('div', {
    staticClass: "mt-n8 mt-md-n14"
  }, [_c('myinfo-row', {
    attrs: {
      "label": "이름"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : (_vm$form8$meta = _vm$form8.meta) === null || _vm$form8$meta === void 0 ? void 0 : _vm$form8$meta.name) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "연락처"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : (_vm$form9$meta = _vm$form9.meta) === null || _vm$form9$meta === void 0 ? void 0 : _vm$form9$meta.phone) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "이메일"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form10 = _vm.form) === null || _vm$form10 === void 0 ? void 0 : (_vm$form10$meta = _vm$form10.meta) === null || _vm$form10$meta === void 0 ? void 0 : _vm$form10$meta.email) || "-") + " ")])], 1)]), _c('v-divider')], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(2), _c('v-divider', {
    staticClass: "primary border-2"
  }), _c('div', {
    staticClass: "py-20 px-md-10 py-md-36"
  }, [_c('div', {
    staticClass: "mt-n8 mt-md-n14"
  }, [_c('myinfo-row', {
    attrs: {
      "label": "제목"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form11 = _vm.form) === null || _vm$form11 === void 0 ? void 0 : _vm$form11.subject) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "내용"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form12 = _vm.form) === null || _vm$form12 === void 0 ? void 0 : _vm$form12.content) || "-") + " ")])], 1)]), _c('v-divider')], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(3), _c('v-card', {
    staticClass: "grey-ef",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-16 pa-md-24"
  }, [_c('div', {
    staticClass: "page-text",
    domProps: {
      "innerHTML": _vm._s(((_vm$form13 = _vm.form) === null || _vm$form13 === void 0 ? void 0 : _vm$form13.reply) || '답변 내용이 없습니다.')
    }
  })])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "grey-cb"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("목록으로")])])], 1)])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("문의 작품")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("문의자 정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("문의내용")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("답변 내용")])]);

}]

export { render, staticRenderFns }